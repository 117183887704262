import React, { useState } from "react";

import Card from "@material-ui/core/Card";
import axios from "axios";
import { useToken } from "../../helpers/Token";

import { Grid, IconButton, Typography, TextField } from "@material-ui/core";
import { ArrowRight } from "@material-ui/icons";

import participantDataCard from "./participantDataCard";
import { API_URL } from "../../helpers/Constants";

export default function Summary() {
  let nameValue = "";
  function handleChange(x) {
    nameValue = x.target.value;
  }

  const [dataCards, setDataCards] = useState([]);
  const { token, setToken } = useToken();
  const userToken = {
    Authorization: `Bearer ${JSON.parse(token)["access_token"]}`,
  };

  function handleClick() {
    axios
      .get(API_URL + `/data/create/${nameValue}`, {
        headers: userToken,
      })
      .then((response) => {
        window.location.reload();
      });
  }

  React.useEffect(() => {
    axios
      .get(API_URL + `/data/participants`, {
        headers: userToken,
      })
      .then((response) => setDataCards(eval(response.data)));
  }, []);

  return (
    <div>
      <Typography variant="h3">Add Participant</Typography>
      <Card style={{ padding: 24, margin: 24, maxWidth: 500 }}>
        <Typography variant="body1">
          Type a name coresponding to a participant and click the arrow.
        </Typography>
        <Typography variant="body1">
          This will lead you to a page with a QR code which can be used to
          generate session that will be tied to the participant and project.
        </Typography>

        <TextField
          id="filled-basic"
          label="Participant Name"
          variant="filled"
          onChange={handleChange}
          style={{ flexGrow: 10 }}
        />
        <IconButton
          size="large"
          edge="end"
          aria-label="logout"
          onClick={handleClick}
        >
          <ArrowRight />
        </IconButton>
      </Card>
      <Typography variant="h3">Participants</Typography>
      <Grid container spacing={2} style={{ padding: 12 }}>
        {dataCards.map((x) => participantDataCard(x))}
      </Grid>
      Create new user Access data
    </div>
  );
}
