import * as React from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import ExitToApp from "@material-ui/icons/ExitToApp";






export default function HeaderAppbar() {
  const [open, setOpen] = React.useState(false);


  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.reload(false);
  };
  return (
    <Box sx={{ display: "static", flexGrow: 1 }}>
      <AppBar
        position="fixed"
        style={{ zIndex: (theme) => theme.zIndex.drawer + 10 }}
      >
        <Toolbar>
          <div style={{ flexGrow: 1 }}></div>
          <IconButton
            size="large"
            edge="end"
            aria-label="logout"
            onClick={handleLogout}
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <ExitToApp />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* <Drawer
        variant="presistant"
        open={open}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
        <Box sx={{ overflow: "auto" }}>
          <Divider />
          <List>
            <ListItem button key="QR Generator">
              <ListItemIcon>
                {1 % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary="QR Generator" />
            </ListItem>
            <ListItem button key="Data">
              <ListItemIcon>
                {1 % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary="Data" />
            </ListItem>

            <ListItem button key="Studies">
              <ListItemIcon>
                {1 % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary="Studies" />
            </ListItem>
            <Box
              sx={{
                flexGrow: 10,
              }}
            ></Box>
            <Divider />
            <ListItem button key="Logout">
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
          <Divider />
        </Box>
      </Drawer> */}
    </Box>
  );
}
