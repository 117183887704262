import React from "react";
import { useQrious } from "react-qrious";

const centred_style = {
  display: "block",
  textAlign: "center",
  top: "50%",
};

export default function QRGeneratorReadOnly(props) {
  const details = { u: props.participant_name };
  const [dataUrl, _qrious] = useQrious({
    value: JSON.stringify(details),
    size: props.size ? props.size : 400,
    level: "H",
  });
  return (
    <div style={centred_style}>
      <img
        level="h"
        src={dataUrl}
        alt={JSON.stringify(details)}
        download={props.participant_name + ".png"}
      />
    </div>
  );
}
