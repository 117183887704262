import { useState } from "react";

export function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    localStorage.setItem("token", JSON.stringify(userToken));
    setToken(userToken.token);
  };
  return {
    token: token,
    setToken: saveToken,
  };
}

export function useTokenHeader() {
  const { token, setToken } = useToken();
  const userToken = {
    Authorization: `Bearer ${JSON.parse(token)["access_token"]}`,
  };
  return userToken;
}

export function Logout() {
    localStorage.removeItem("token");
  
}
