import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import "./Login.css";
import { API_URL } from "../../helpers/Constants";

async function loginUser(credentials) {
  return axios
    .post(
      API_URL + "/auth/token",
      `grant_type=&username=${credentials.username}&password=${credentials.password}&scope=&client_id=&client_secret=`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          "Access-Control-Allow-Headers":
            "append,delete,entries,foreach,get,has,keys,set,values,Authorization",
        },
      }
    )
    .then((response) => JSON.stringify(response.data));
}
const rootStyle = {
  "& > *": {
    margin: 15,
  },
};
const cardStyle = {
  minWidth: 275,
  width: 475,
  padding: "40px 40px",
};

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};
export default function Login({ setToken }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await loginUser({
      username,
      password,
    });
    setToken(token);
    window.location.reload(false);
  };

  return (
    <div className="login-wrapper">
      <Card style={cardStyle}>
        <CardContent>
          <Form onSubmit={handleSubmit}>
            <div className="form-group">
              <TextField
                style={rootStyle}
                type="text"
                label="Username"
                className="form-control"
                name="username"
                value={username}
                onChange={(e) => setUserName(e.target.value)}
                validations={[required]}
              />
            </div>

            <div className="form-group">
              <TextField
                style={rootStyle}
                type="password"
                label="Password"
                className="form-control"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                validations={[required]}
              />
            </div>

            <div className="form-group">
              <button className="btn btn-primary btn-block">
                <span>Login</span>
              </button>
            </div>
          </Form>
        </CardContent>
      </Card>
    </div>
  );

  return (
    <div className="login-wrapper">
      <h1>Please Log In</h1>
      <form onSubmit={handleSubmit}>
        <label>
          <p>Username</p>
          <input type="text" onChange={(e) => setUserName(e.target.value)} />
        </label>
        <label>
          <p>Password</p>
          <input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
