import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import { Link } from 'react-router-dom';

import {
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Typography
} from "@material-ui/core";
import { ArrowRight } from "@material-ui/icons";
import QRGeneratorReadOnly from "../QRGenerator/QRGeneratorReadOnly";

export default function participantDataCard(data) {
  let timestamp = data._id.toString().substring(0, 8);
  let date = new Date(parseInt(timestamp, 16) * 1000);

  return (
    <Card style={{ display: "flex", margin: 24 }}>
      <CardMedia>
        <QRGeneratorReadOnly size="250" participant_name={data._id} />
      </CardMedia>
      <CardContent>
        <Typography variant="h4">{data.name}</Typography>

        <Typography variant="h5">Date added</Typography>
        <Typography variant="body2">{date.toUTCString()}</Typography>

        <Typography variant="h5">User ID</Typography>
        <Typography variant="body2">{data._id}</Typography>

        <Typography variant="h5">Project ID</Typography>
        <Typography variant="body2">{data.project}</Typography>

        <Typography variant="h5">Creator ID</Typography>
        <Typography variant="body2">{data.creator}</Typography>
      </CardContent>
      <CardActions>
        <IconButton component={Link} to={"/u/" + data._id}>
          <ArrowRight />
        </IconButton>
      </CardActions>
    </Card>
  );
}
