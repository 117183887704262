import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import axios from "axios";
import {
  CardActions,
  CardContent,
  IconButton,
} from "@material-ui/core";
import { ArrowDownward } from "@material-ui/icons";
import { useToken } from "../../helpers/Token";
import { API_URL } from "../../helpers/Constants";

export function downloadData(participant_name, userToken) {
  axios
    .get(API_URL + `/data/participant/${participant_name}.json`, {
      headers: userToken,
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${participant_name}.json`);
      document.body.appendChild(link);
      link.click();
    });
}

export default function UserDataSummaryCard(name) {
  const { token, setToken } = useToken();
  const userToken = {
    Authorization: `Bearer ${JSON.parse(token)["access_token"]}`,
  };

  const [summaryCards, setSummaryCards] = useState([]);
  React.useEffect(() => {
    axios
      .get(API_URL + `data/participant/${name}/summary`, {
        headers: userToken,
      })
      .then((response) => setSummaryCards(eval(response.data)));
  }, []);

  return (
    <Card style={{ display: "flex", maxWidth: 450, margin: 20 }}>
      <CardContent>
        {/* {summaryCards.map(d => dataPoints(d))} */}
        {console.log(summaryCards.data)}
      </CardContent>
      <CardActions>
        <IconButton onClick={() => downloadData(name, userToken)}>
          <ArrowDownward />
        </IconButton>
      </CardActions>
    </Card>
  );
}
