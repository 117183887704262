import React from 'react';
import Card from '@material-ui/core/Card';
import axios from "axios";
import {  CardActions, CardContent, IconButton, Typography } from '@material-ui/core';
import { ArrowDownward } from '@material-ui/icons'
import { API_URL } from "../../helpers/Constants";

const token = { "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2MDJiYTExNDJjNWVjYTZiN2IyNDEyZTQiLCJleHAiOjE2NDA2NDMxNzV9.aZBKdn97AdzZf7i7T-p02szzEDEFGDimV6Pc2JLxsds" }

function downloadData(participant_name) {
  axios
    .get(API_URL + `/data/id/${participant_name}.json`, {
      headers: token,
      responseType: 'blob',
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${participant_name}.json`);
      document.body.appendChild(link);
      link.click();
    });
}

export default function UserDataCard(data) {
  let timestamp = data._id.toString().substring(0, 8)
  let date = new Date(parseInt(timestamp, 16) * 1000)

  return (<Card style={{ display: 'flex', maxWidth: 450, margin: 20 }}>
    <CardContent>
      <Typography variant='h5'>{date.toUTCString()}</Typography>
      <Typography variant='subtitle1'>{data.tag}</Typography>
      <Typography variant='body2'>{data.name}</Typography>
    </CardContent>
    <CardActions>
      <IconButton onClick={() => downloadData(data._id)}>
        <ArrowDownward />
      </IconButton>
    </CardActions>
  </Card>)
}