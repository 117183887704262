import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useToken } from "../../helpers/Token";
import {
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import UserDataCard from "./userDataCard";
import UserDataSummaryCard from "./userDataSummaryCard";
import QRGeneratorReadOnly from "../QRGenerator/QRGeneratorReadOnly";
import { API_URL } from "../../helpers/Constants";

export default function UserSummary() {
  const { participant_name } = useParams();

  const [dataCards, setDataCards] = useState([]);
  const [sessionDetails, setValues] = useState({
    g: "study_name",
    u: participant_name,
  });

  const { token, setToken } = useToken();
  const userToken = {
    Authorization: `Bearer ${JSON.parse(token)["access_token"]}`,
  };

  React.useEffect(() => {
    axios
      .get(API_URL + `/data/participant/${sessionDetails.u}`, {
        headers: userToken,
      })
      .then((response) => setDataCards(eval(response.data)));
  }, []);

  return (
    <Box>
      <QRGeneratorReadOnly size="600" participant_name={sessionDetails.u} />
      <Typography variant="h4">Summary</Typography>
      {UserDataSummaryCard(sessionDetails.u)}
      <Typography variant="h4">Mesurments</Typography>
      <Grid container spacing={2}>
        {dataCards.map((x) => UserDataCard(x))}
      </Grid>
    </Box>
  );
}
