import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import './App.css';
import Dashboard from '../Dashboard/Dashboard';
import Login from '../Login/Login';
import UserSummary from '../UserSummary/UserSummary'

import { history } from '../../helpers/History';
import HeaderAppbar from '../HeaderAppbar/HeaderAppbar';
import Summary from '../Summary/Summary';
import { useToken } from '../../helpers/Token';


function App() {

  const { token, setToken } = useToken();
  if (!token) {
    return <Login setToken={setToken} />
  }

  return (
    <div className="wrapper" style={{padding:"72px 24px 8px 24px"}}>
      <HeaderAppbar />
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Summary} />

          <Route path="/dashboard">
            <Dashboard />
          </Route>

          <Route path="/u/:participant_name" component={UserSummary} />
          <Route>
            <div>404</div>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;